import { useState } from "react";

export const useCreditCardForm = (callback) => {
  const [inputs, setInputs] = useState({
    public: {
      apellido: '',
      nombre: '',
      dni: '',
      codigoPostal: '',
      altura: '',
      direccion: '',
      codigoArea: '',
      telefono: '',
      documentType: '',
      email: '',
      paymentMethodId: ''
    },
    private: {
      creditCardNumber: '',
      expiration: '',
      cardHolderName: '',
      cvv: ''
    }
  });

  const handleSubmit = event => {
    
    if (event) {
      event.preventDefault();
    }
    return callback();
  };

  const handleInputChange = event => {
    event.persist();

    if (event.target.name) {
      setInputs(inputs => ({
        ...inputs,
        public: {
          ...inputs.public,
          [event.target.name]: event.target.value
        }
      }));
    } else {
      setInputs(inputs => ({
        ...inputs,
        private: {
          ...inputs.private,
          [event.target.dataset.checkout]: event.target.value
        }
      }));
    }
  };
  const handleFieldChange = field => {
    setInputs(inputs => ({
      ...inputs,
      public: {
        ...inputs.public,
        [field.name]: field.value
      }
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    handleFieldChange,
    inputs
  };
};
