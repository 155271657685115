import React, {Fragment, useState, useEffect} from 'react';
import swal from 'sweetalert';

function PayerData({ mercadoPagoService, inputs, stepDispatch, handleFieldChange, handleInputChange, handleSubmit}) {
  const [documentTypeValidation, setDocumentTypeValidation] = useState({
    min: 1,
    max: 9
  });
  const [renderDocumentTypes, setRenderDocumentTypes] = useState([]);
  const [enviando, setEnviando] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      let data = await mercadoPagoService.getIdentificationTypes();
      let result = data.map(type => <option key={type.id}
          data-minval={type.min_length} 
          data-maxval={type.max_length} 
          value={type.id}>{type.name}
      </option>);
      handleFieldChange({
        value: data[0].id,
        name: 'documentType'
      });
      setDocumentTypeValidation({
        min: data[0].min_length,
        max: data[0].max_length
      });
      setRenderDocumentTypes(result);
    };
    fetchData();
  }, []);
  
  const setDocumentTypeWrapper = (type, typeValidation) => {
    setDocumentTypeValidation(typeValidation);
    handleFieldChange({
      name: 'documentType',
      value: type
    });
  }
  
  const prev = () => {
    stepDispatch({type: 'CREDIT_CARD'});
  }
  const finalizar = async (e) => {
    //check if is valid (cvv) (exp) if not empty (nomb) (cc_number)
    if (inputs.public.apellido.length === 0) {
        swal('El apellido es requerido', '','error');
        return;
    }
    if (inputs.public.nombre.length === 0) {
        swal('El nombre es requerido', '','error');
        return;
    }
    if (inputs.public.dni.length < documentTypeValidation.min || inputs.public.dni.length > documentTypeValidation.max) {
        swal(`Error en la longitud del documento debe ser entre ${documentTypeValidation.min} ${documentTypeValidation.max}`, '','error');
        return;
    }
    if (inputs.public.direccion.length === 0) {
      swal('La dirección es requerida', '','error');
      return;
    }
    if (inputs.public.codigoPostal.length === 0) {
      swal('La dirección es requerida', '','error');
      return;
    }
    if (inputs.public.altura.length === 0) {
      swal('La dirección es requerida', '','error');
      return;
    }
    if (inputs.public.codigoArea.length === 0) {
      swal('El teléfono es requerido', '','error');
      return;
    }
    if (inputs.public.telefono.length === 0) {
      swal('El teléfono es requerido', '','error');
      return;
    }
    if (inputs.public.documentType.length === 0) {
      swal('El tipo de documento es requerido', '','error');
      return;
    }
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(inputs.public.email).toLowerCase())) {
        swal('Ingrese una dirección de mail válida.', '','error');
        return;
    }
    try {
      setEnviando(true);
      const result = await handleSubmit(e);
      console.log({
        result
      })
      setEnviando(false);
      if (result) {
        stepDispatch({type: 'SUCCESS'});
      } else {
        stepDispatch({type: 'ERROR'});
      }
    } catch {
      setEnviando(false);
      stepDispatch({type: 'ERROR'});
    }
  }
  return (
    <Fragment>
      <h1 style={{textAlign: 'left'}}>Datos de facturación</h1>
      <form style={{display: 'flex', flexWrap: 'wrap'}}>
        <div className="form-group" style={{ width: '50%', paddingRight: '5px'}}>
          <input type="text" value={inputs.public.apellido} onChange={e => handleInputChange(e)} name='apellido' required="required" />
          <label htmlFor="input" className="control-label">Apellido</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '50%'}}>
          <input type="text" value={inputs.public.nombre} onChange={e => handleInputChange(e)} name='nombre' required="required" />
          <label htmlFor="input" className="control-label">Nombre</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '30%', paddingRight: '5px'}}>
          <select value={inputs.public.documentType} name='documentType' onChange={e => setDocumentTypeWrapper(e.target.value, {min: e.target.dataset.minval, max: e.target.dataset.maxval})} >
            {renderDocumentTypes}
          </select>
          <label htmlFor="select" className="control-label">Tipo de documento</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '70%'}}>
          <input type="text" value={inputs.public.dni} name='dni' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Documento</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '50%', paddingRight: '5px'}}>
          <input type="text" value={inputs.public.direccion} name='direccion' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Dirección</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '25%', paddingRight: '5px'}}>
          <input type="text" value={inputs.public.altura} name='altura' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Altura</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '25%'}}>
          <input type="text" value={inputs.public.codigoPostal} name='codigoPostal' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Código Postal</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '30%', paddingRight: '5px'}}>
          <input type="text" value={inputs.public.codigoArea} name='codigoArea' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Código de área</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '70%'}}>
          <input type="text" value={inputs.public.telefono} name='telefono' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Teléfono</label><i className="bar"></i>
        </div>
        <div className="form-group" style={{ width: '100%'}}>
          <input type="text" value={inputs.public.email} name='email' onChange={e => handleInputChange(e)} required="required" />
          <label htmlFor="input" className="control-label">Email</label><i className="bar"></i>
        </div>
        <div className="button-container">
          <button type="button" onClick={e => prev()} disabled={enviando} className="button" style={{marginRight: '10px'}}><span>Volver</span></button>
          <button type="button" onClick={e => finalizar(e)} disabled={enviando} className="button"><span>Guardar</span></button>
          {
            enviando &&
            <p style={{padding: '1rem'}}>
              Su pago está siendo realizando por favor espere...
            </p>
          }
        </div>
      </form>
    </Fragment>
  );
}

export default PayerData;
