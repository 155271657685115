import React, { useState, useEffect, Fragment } from 'react';
import PlanSidebar from './PlanSidebar';
import './App.css';
import CreditCard from './CreditCard';
import PayerData from './PayerData';
import MercadoPagoService from './MercadoPagoService';
import {useCreditCardForm} from './useCreditCardForm';
import states from './state.constant';
import swal from 'sweetalert';


function Success () {
  return <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: '50px', alignContent: 'center'}}>
      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
      </svg>
      <div style={{margin:'auto', textAlign: 'center', fontSize: '2rem'}}>El pago fue realizado con éxito</div>
    </div>;
}

function Fail ({stepDispatch}) {
  return <Fragment>
      <div>El pago falló, revise los datos e intentelo más tarde.</div>
      <div className="button-container">
        <button type="button" onClick={e => stepDispatch({type: 'PAYER_DATA'})} className="button">
          <span>Volver</span>
        </button>
      </div>
    </Fragment>;
}

const mercadoPagoService = new MercadoPagoService();

function App ({planId}) {
  const stepReducer = (state, action) => {
    switch (action.type) {
      case 'PAYER_DATA':
        return states.PAYER_DATA;
      case 'CREDIT_CARD':
        return states.CREDIT_CARD;
      case 'SUCCESS':
        return states.SUCCESS;
      case 'ERROR':
        return states.ERROR;
      default:
      break;
    }
  }
  const [step, stepDispatch] = React.useReducer(stepReducer, states.CREDIT_CARD);
  const [mercadoPagoLoading, setMercadoPagoLoading] = useState(true);
  const enviarForm = async () => {
    try {
      
      const dataPublic = inputs.public;
      const creditCardData = {
        cardNumber: inputs.private.creditCardNumber,
        securityCode: inputs.private.cvv,
        cardExpirationMonth: inputs.private.expiration.split('/')[0],
        cardExpirationYear: inputs.private.expiration.split('/')[1],
        cardholderName: inputs.private.cardHolderName,
        docType: inputs.public.documentType,
        docNumber: inputs.public.dni,
        email: inputs.public.email
      };
      dataPublic.tokenData = await mercadoPagoService.createToken(creditCardData);
      dataPublic.plan_id = this.props.planId;
      const result = await fetch('http://localhost:8000/api/payments', {
        method: 'POST',
        body: JSON.stringify(dataPublic),
        headers:{
          'Content-Type': 'application/json'
        }
      });
      const data = await result.json();
      
      if (data) {
        
        if(data.status !== 'ok')
        {
          swal('','No se puedo registrar su pago, intente nuevamente más tarde.', 'error');
          return false;  
        }
        console.log(data);
        return true;
      } else {
        swal('','No se puedo registrar su pago, intente nuevamente más tarde.', 'error');
        return false;
      }
    } catch (err) {
      /**
       * data:
        cause: Array(1)
        0:
        code: "E301"
        description
       */
      swal('','No se puedo registrar su pago, intente nuevamente más tarde.', 'error');
      return false;
    } finally {
      mercadoPagoService.clearSession();
    }
  };
  
  const { inputs, handleInputChange, handleFieldChange, handleSubmit } = useCreditCardForm(enviarForm);

  useEffect(() => {
    // const publicKey = 'TEST-7fb11440-c933-490a-a4f3-9a82c7f0be98';
    const publicKey = 'APP_USR-2d6468bc-b72f-4894-992a-e7e95e0825c6';
    mercadoPagoService.init(publicKey).then(() => {
      setMercadoPagoLoading(false);
    }).catch(() => {
      swal('','ocurrio un error recargue la página por favor.', 'error');
    })
  }, [mercadoPagoLoading]);

  function renderSteps () {
    switch (step) {
      case states.CREDIT_CARD:
        return <CreditCard mercadoPagoService={mercadoPagoService} inputs={inputs} handleFieldChange={handleFieldChange} stepDispatch={stepDispatch} handleInputChange={handleInputChange} />
      case states.PAYER_DATA:
        return <PayerData mercadoPagoService={mercadoPagoService} inputs={inputs} handleFieldChange={handleFieldChange} stepDispatch={stepDispatch} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
      case states.SUCCESS:
        return <Success />
      case states.ERROR:
        return <Fail stepDispatch={stepDispatch} />
      default:
        break;
    }
  }
  return (
      <Fragment>
        <div className="container">
          {
            
            <div className="main-content">
              {
               !mercadoPagoLoading && renderSteps()
              }
              {mercadoPagoLoading &&
               <h1>
                 Cargando... 
               </h1>
              }
            </div>
          }
          <PlanSidebar planId={planId} />
        </div>
        <a className="inspiration" href="https://codepen.io/FilipVitas/pen/ddLVZx" rel="noopener noreferrer" target="_blank">Créditos</a>
    </Fragment>
  );
}

export default App;
