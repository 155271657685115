export default class PlanService {
    getPlan = async (planId) => {
        const result = await fetch(`http://localhost:8000/api/planes/${planId}`, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
        });
        return result.json();
    }
}