import React, { Fragment, useState, useEffect } from 'react';
import swal from 'sweetalert';

let paymentMethodCache = {};

function CreditCard({ mercadoPagoService, inputs, stepDispatch, handleFieldChange, handleInputChange }) {
    const [N, setN] = useState(16);
    const [paymentMethodName, setPaymentMethodName] = useState('');
    const [cvvData, setCvvData] = useState({
        length: 3,
        location: 'back'
    });
    const [flipped, setFlipped] = useState(false);
    const setFlippedWrapper = (condition) => {
        if (cvvData.location === 'back') {
            setFlipped(condition);
        }
    }
    const [pattern, setPattern] = useState([4, 4, 4, 4]);
    const selectPattern = (len) => {
        switch (len) {
            case 13: 
                return [4, 4, 4, 1];
            case 14: 
                return [4, 6, 4];
            case 15: 
                return [4, 6, 5];
            case 16: 
                return [4, 4, 4, 4];
            case 18: 
                return [10, 5, 3];
            case 19: 
                return [9, 10];
            default: 
                return [4, 4, 4, 4, 4, 4];
        }
    }
    const isValidIIN = (iin) => {
        return /^[0-9]*$/.test(iin);
    }
    const setCreditCardNumberWrapper = async (e) => {
        const iin = e.target.value;
        if (!isValidIIN(iin) ) return;
        handleInputChange(e);   
        setCreditCardNumber(iin);
    }
    const setCreditCardNumber = async(iin) => {
        if(iin.length < 6) return;
        let bin = iin.substring(0, 6);
        try {
            let paymentMethod = undefined;
            if (typeof paymentMethodCache[bin] === "undefined") {
                paymentMethodCache[bin] = await mercadoPagoService.getPaymentMethod(bin)
                console.log('cache miss');
            }
            paymentMethod = paymentMethodCache[bin];
            if (!paymentMethod ||
                paymentMethod.length === 0 ||
                paymentMethod[0].settings.length === 0) {
                return;
            }
            let n = paymentMethod[0].settings[0].card_number.length;
            let cvvLength = paymentMethod[0].settings[0].security_code.length;
            let cvvLocation = paymentMethod[0].settings[0].security_code.card_location;
            setN(n);
            setPaymentMethodName(paymentMethod[0].name);
            setCvvData({
                length: cvvLength,
                location: cvvLocation
            });
            handleFieldChange({
                name: 'paymentMethodId',
                value: paymentMethod[0].id
            });
            setPattern(selectPattern(n));
        } catch (error) {
            setN(16);
            setCvvData({
                length: 3,
                location: 'back'
            });
            setPaymentMethodName('');
            handleFieldChange({
                name: 'paymentMethodId',
                value: null
            });
            setPattern(selectPattern(16));
            paymentMethodCache[bin] = null;
            console.log({error});
        }
    }
    useEffect(() => {
        setCreditCardNumber(inputs.private.creditCardNumber);
    }, [])
    const renderDisplayNumber = (creditCardNumber) => {
        const displayNumber = creditCardNumber + (new Array(N - creditCardNumber.length)).fill('*').join('');
        let groupIndex = 0;
        let lastGroup = pattern[0];
        var displayNumberGroups = displayNumber.split('').reduce((prev, current, index) => {
            if (index === lastGroup) {
                groupIndex++;
                lastGroup += pattern[groupIndex];
            }
            prev[groupIndex] = prev[groupIndex] || '';
            prev[groupIndex] = prev[groupIndex] + current;
            return prev;
        }, []);
        return displayNumberGroups.map((subNumber, index) => 
            <div key={index} className="section">{subNumber}</div>
        );
    }

    const next = () => {
        //check if is valid (cvv) (exp) if not empty (nomb) (cc_number)
        if (paymentMethodName.length === 0) {
            swal('Revise el número de tarjeta', '', 'error');
            return;
        }
        if (inputs.private.creditCardNumber.length === 0) {
            swal('Número de tarjeta requerido', '', 'error');
            return;
        }
        if (inputs.private.cvv.length !== cvvData.length) {
            alert(`Error en la longitud del código de seguridad se esperaban ${cvvData.length} dígitos`);
            return;
        }
        if (!/^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(inputs.private.expiration)) {
            swal('Formato de fecha de expiración incorrecto debe ser: MM/AA', '', 'error');
            return;
        }
        if (inputs.private.cardHolderName.length === 0) {
            swal('El nombre es requerido', '', 'error');
            return;
        }
        stepDispatch({type: 'PAYER_DATA'});
    }
    
    return (
        <Fragment>
        <h1 style={{ textAlign: 'left' }}>Ingresá los datos de la tarjeta</h1>
        <div className={`card ${flipped?'flipped':''} payment-card__brand-banco-bica`}>
            <div className="flip">
                <div className="front">
                    <div className="strip-bottom"></div>
                    <div className="strip-top"></div>
                    <div className="logo" width="40" height="40">
                        {/* <div id="payment-card__logo" className="payment-card__logo-bank"></div> */}
                    </div>
                    <div className="investor">{paymentMethodName}</div>
                    <div className="chip">
                        <div className="chip-line"></div>
                        <div className="chip-line"></div>
                        <div className="chip-line"></div>
                        <div className="chip-line"></div>
                        <div className="chip-main"></div>
                    </div>
                    <svg className="wave" viewBox="0 3.71 26.959 38.787" width="26.959" height="38.787" fill="white">
                        <path
                            d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z">
                        </path>
                        <path
                            d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z">
                        </path>
                        <path
                            d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z">
                        </path>
                    </svg>
                    {
                        cvvData.location === 'front' &&
                        <div className="ccv">
                            <label>ccv</label>
                            <div>{inputs.private.cvv}</div>
                        </div>
                    }
                    <div className="card-number">
                        {renderDisplayNumber(inputs.private.creditCardNumber)}
                    </div>
                    <div className="end">
                        <span className="end-text">Expiración:</span>
                        <span className="end-date">{inputs.private.expiration}</span>
                    </div>
                    <div className="card-holder">{inputs.private.cardHolderName === ""? 'Nombre y Apellido' : inputs.private.cardHolderName}</div>
                    <div className="master"></div>
                </div>
                <div className="back">
                    <div className="strip-black"></div>
                    {
                        cvvData.location === 'back' &&
                        <div className="ccv">
                            <label>ccv</label>
                            <div>{inputs.private.cvv}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
        <form style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="form-group" style={{ width: '60%', paddingRight: '0.9rem' }}>
                <input type="text" value={inputs.private.creditCardNumber} maxLength={N} onChange={e => setCreditCardNumberWrapper(e)} data-checkout='creditCardNumber' required="required" />
                <label htmlFor="input" className="control-label">Número de la tarjeta</label><i className="bar"></i>
            </div>
            <div className="form-group" style={{ width: '40%' }}>
                <input type="text" value={inputs.private.expiration} onChange={e => handleInputChange(e)} data-checkout='expiration' maxLength={5} placeholder="MM/AA" required="required" />
                <label htmlFor="input" className="control-label">Fecha de vencimiento</label><i className="bar"></i>
            </div>
            <div className="form-group" style={{ width: '60%', paddingRight: '0.9rem' }}>
                <input type="text" value={inputs.private.cardHolderName} onChange={e => handleInputChange(e)} data-checkout='cardHolderName' required="required" />
                <label htmlFor="input" className="control-label">Nombre que figura en la tarjeta</label><i className="bar"></i>
            </div>
            <div className="form-group" style={{ width: '40%' }}>
                <input type="text" value={inputs.private.cvv} onFocus={e => setFlippedWrapper(true)} onBlur={e => setFlippedWrapper(false)} onChange={e => handleInputChange(e)} data-checkout='cvv' maxLength={cvvData.length} required="required" />
                <label htmlFor="input" className="control-label">Código de seguridad</label><i className="bar"></i>
            </div>
        </form>
        <div className="button-container">
            <button type="button" onClick={e => next()} className="button"><span>Siguiente</span></button>
        </div>
        </Fragment>
    );
}

export default CreditCard;
