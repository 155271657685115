import React, { useState, useEffect } from 'react';
import PlanService from './PlanService';

const planService = new PlanService();

function PlanSidebar({planId}) {
    const [plan, setPlan] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let data = await planService.getPlan(planId     );
            setPlan(data);
            console.log(data);
        };
        fetchData();
    }, []);
    return (
        <div className="plan-sidebar">
            <h1>{plan.nombre}</h1>
            <p dangerouslySetInnerHTML={{__html: plan.descripcion}} />
            <div className="price-box">
                <div className="big-number">
                    $ {plan.precio}
                </div>
                <div>
                    cada {plan.dias} días
                </div>
            </div>
        </div>
    );
}

export default PlanSidebar;
