export default class MercadoPagoService {

    init = async (apiKey) => {
        return new Promise(
            (resolve, reject) => {
                if (document.getElementById("__mercadopago__id_travelpaq_")) {
                    window.Mercadopago.clearSession();
                    window.Mercadopago.setPublishableKey(apiKey);
                    resolve();
                    return;
                }
                let js = document.createElement("script");
                js.id = "__mercadopago__id_travelpaq_";
                js.src="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js";
                js.onload=() => {
                    window.Mercadopago.setPublishableKey(apiKey);
                    resolve();
                };
                js.onerror=reject;
                document.body.appendChild(js)
            }
        );
    }
    
    getIdentificationTypes = () => {
        return new Promise(
            (resolve, reject) => {
                window.Mercadopago.getIdentificationTypes((statusCode, types) => {
                    if(!types) 
                    {
                        reject({
                            response: '',
                            statusCode: statusCode,
                            msg: 'No response'
                        });
                    }
                    if((+statusCode) !== 200 && (+statusCode) !== 201)
                    {
                        reject({
                            data: types,
                            statusCode: statusCode,
                            msg: 'Fail at getPaymentMethod'
                        });
                    }
                    resolve(types);
                });
            }
        );
    }
    clearSession = () => {
        window.Mercadopago.clearSession();
    }

    getPaymentMethod = (bin) => {
        return new Promise(
            (resolve, reject) => {
                window.Mercadopago.getPaymentMethod({
                    "bin": bin
                }, ((statusCode, response) => {
                    if(!response) 
                    {
                        reject({
                            response: '',
                            statusCode: statusCode,
                            msg: 'No response'
                        });
                    }
                    if((+statusCode) !== 200 && (+statusCode) !== 201)
                    {
                        reject({
                            data: response,
                            statusCode: statusCode,
                            msg: 'Fail at getPaymentMethod'
                        });
                    }
                    resolve(response);
                  })
                );
            }
        );
    }

    getInstallments = (bin, amount) => {
        return new Promise(
            (resolve, reject) => {
                window.Mercadopago.getInstallments({
                    "bin": bin,
                    "amount": amount,
                }, 
                    ((statusCode, response) => {
                        if(!response) 
                        {
                            reject({
                                response: '',
                                statusCode: statusCode,
                                msg: 'No response'
                            });
                        }
                        if((+statusCode) !== 200 && (+statusCode) !== 201)
                        {
                            reject({
                                data: response,
                                statusCode: statusCode,
                                msg: 'Fail at getPaymentMethod'
                            });
                        }
                        resolve(response);
                    })
                );
            }
        );
    }

    createToken = (cardData) => {
        return new Promise(
            (resolve, reject) => {
                window.Mercadopago.createToken(cardData, 
                    ((statusCode, response) => {
                        if(!response) 
                        {
                            reject({
                                response: '',
                                statusCode: statusCode,
                                msg: 'No response'
                            });
                        }
                        if((+statusCode) !== 200 && (+statusCode) !== 201)
                        {
                            reject({
                                data: response,
                                statusCode: statusCode,
                                msg: 'Fail at getPaymentMethod'
                            });
                        }
                        resolve(response);
                    })
                );
            }
        );
    }
}